<template src="./DigitalPass.html"></template>
<script>
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  CardImage,
  CardActions,
} from "@progress/kendo-vue-layout";
import { Button } from "@progress/kendo-vue-buttons";
import { Input } from "@progress/kendo-vue-inputs";
import { DropDownList } from "@progress/kendo-vue-dropdowns";
import { Popup } from "@progress/kendo-vue-popup";
import AppPopUp from "../../common/AppPopUp/AppPopUp";
import DigitalPassTemplateDeleteModal from "./digitalPassTemplateDeleteModal/DigitalPassTemplateDeleteModal.vue";
import Breadcrumb from "../../common/AppBreadCrumbs/BreadCrumbs.vue";
import AppSnackBar from "../../common/AppSnackBar/AppSnackBar";
import AppLoader from "../../common/AppLoader/AppLoader";
import club from "@/store/clubDetails/club";
export default {
  name: "DigitalPass",
  components: {
    kbutton: Button,
    "k-input": Input,
    dropdownlist: DropDownList,
    card: Card,
    cardHeader: CardHeader,
    cardBody: CardBody,
    cardTitle: CardTitle,
    cardImage: CardImage,
    cardActions: CardActions,
    Popup: Popup,
    AppPopUp,
    Breadcrumb,
    DigitalPassTemplateDeleteModal: DigitalPassTemplateDeleteModal,
    AppSnackBar,
    AppLoader,
  },
  data() {
    return {
      itemCode: "allDigitalPass",
      moduleCode: "digitalPass",
      itemTagTypes: {
        1: "dpAudience",
        2: "ecAudience",
        3: "vAudience",
      },
      moduleTagTypes: {
        1: "digitalPass",
        2: "emailCampaign",
        3: "voucher",
      },
      childItemCodeType: {
        1: "dpAllAudience",
        2: "ecAllAudience",
        3: "vAllAudience",
      },
      clubId: this.$route.params.id,
      searchQuery: "",
      cardIdDlt: "",
      cardId: 0,
      searchingLoading: false,
      timer: null,
      templates: [
        { text: "All", id: 0 },
        { text: "Loyalty Card", id: 1 },
        { text: "Membership Card", id: 2 },
        { text: "Event Ticket", id: 3 },
      ],
      MoreOptionDropdownitems: [
        {
          text: "Duplicate",
          dropId: 1,
        },
        {
          text: "Delete",
          dropId: 2,
        },
      ],
      passTypesValue: { text: "All", id: 0 },
      //deleteModalVisible: false,
      show: true,
      templateList: [
        {
          cardType: "Loyalty",
          templateName: "AAAAA",
        },
        {
          cardType: "Coupon",
          templateName: "BBBBB",
        },
        {
          cardType: "Event Ticket",
          templateName: "AAAAA",
        },
        {
          cardType: "Loyalty",
          templateName: "CCCCC",
        },
        {
          cardType: "Coupon",
          templateName: "GGGGG",
        },
      ],
      cardDropDown: [
        {
          text: "Delete",
          dropId: 1,
        },
      ],
      searching: false,
    };
  },
  mounted() {
    if (!this.hasGetAccess()) {
      this.$store.commit("SHOW_NOTIFICATION", {
        message: "You do not have access to view campaigns.",
        type: "warning",
      });
      this.$router.push(`/club-details/${this.$route.params.id}/dashboard`);
    }
    this.getAllTemplates();
  },
  computed: {
    deleteModalVisible() {
      return this.$store.state.digitalPass.deleteModalVisible;
    },
    passTemplates() {
      return this.$store.state.passTemplates.passTemplates;
    },
    passItems() {
      return this.passTemplates.items;
    },
    isItemsavailable() {
      if (this.passItems && this.passItems.length) {
        return true;
      } else {
        return false;
      }
    },
    isshowMessage() {
      return this.$store.state.passTemplates.showMsgPassTemplate;
    },
    showMsg() {
      return this.$store.state.passTemplates.statusPassTemplate.Message;
    },
    isLoading() {
      return this.$store.state.passTemplates.loadingPassTemplate;
    },
    club() {
      return this.$store.getters.clubDetails;
    },
    fetchController() {
      return this.$store.state.passTemplates.fetchController;
    },
  },
  methods: {
    hasGetAccess() {
      return this.$hasAccessToModule({
        permissionKey: "hasGetPermission",
        moduleCode: this.moduleCode,
        itemCode: this.itemCode,
        childItemCode: null,
      });
    },
    hasDeleteAccess() {
      return this.$hasAccessToModule({
        permissionKey: "hasDeletePermission",
        moduleCode: this.moduleCode,
        itemCode: this.itemCode,
        childItemCode: null,
      });
    },
    hasEditAccess() {
      return this.$hasAccessToModule({
        permissionKey: "hasPutPermission",
        moduleCode: this.moduleCode,
        itemCode: this.itemCode,
        childItemCode: null,
      });
    },
    hasCreateAccess() {
      return this.$hasAccessToModule({
        permissionKey: "hasPostPermission",
        moduleCode: this.moduleCode,
        itemCode: this.itemCode,
        childItemCode: null,
      });
    },

    hasAudienceGetAccess() {
      return this.$hasAccessToModule({
        permissionKey: "hasGetPermission",
        moduleCode: this.moduleTagTypes[+this.$route.query.type],
        itemCode: this.itemTagTypes[+this.$route.query.type],
        childItemCode: this.childItemCodeType[+this.$route.query.type],
      });
    },
    hasAudienceDeleteAccess() {
      return this.$hasAccessToModule({
        permissionKey: "hasDeletePermission",
        moduleCode: this.moduleTagTypes[+this.$route.query.type],
        itemCode: this.itemTagTypes[+this.$route.query.type],
        childItemCode: this.childItemCodeType[+this.$route.query.type],
      });
    },
    hasAudienceEditAccess() {
      return this.$hasAccessToModule({
        permissionKey: "hasPutPermission",
        moduleCode: this.moduleTagTypes[+this.$route.query.type],
        itemCode: this.itemTagTypes[+this.$route.query.type],
        childItemCode: this.childItemCodeType[+this.$route.query.type],
      });
    },
    hasAudienceCreateAccess() {
      return this.$hasAccessToModule({
        permissionKey: "hasPostPermission",
        moduleCode: this.moduleTagTypes[+this.$route.query.type],
        itemCode: this.itemTagTypes[+this.$route.query.type],
        childItemCode: this.childItemCodeType[+this.$route.query.type],
      });
    },
    getAllTemplates() {
      let payload = {
        id: this.clubId,
        limit: -1,
        clubId: this.clubId,
      };
      this.$store.dispatch("getPassTemplates", payload);
    },
    navigateToCreatePage() {
      this.$router.push(`/club-details/${this.clubId}/create-pass`);
    },
    onSearch(e) {
      if (e.value == "") {
        this.searching = true;
      }
      this.searchingLoading = true;
      this.searchQuery = e.value;
      this.$store.commit("LOADING_STATUS", true);

      let payload = {
        clubId: this.clubId,
        id: this.clubId,
        SearchText: e.value,
        limit: -1,
        TemplateTypeId: this.passTypesValue.id,
      };

      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }
      this.timer = setTimeout(() => {
        this.$store.dispatch("getPassTemplates", payload);
      }, 800);
    },
    handlePassTypesValue(e) {
      this.searchingLoading = true;
      this.passTypesValue = e.value;

      if (e.value.id != 0) {
        let payload = {
          id: this.clubId,
          clubId: this.clubId,
          limit: -1,
          TemplateTypeId: e.value.id,
          SearchText: this.searchQuery,
        };
        this.$store.dispatch("getPassTemplates", payload);
      } else {
        let payload = {
          id: this.clubId,
          limit: -1,
        };
        this.$store.dispatch("getPassTemplates", payload);
      }
    },
    getTemplateList() {
      this.$store.dispatch("getTemplateList");
    },
    navigateToManageRecipients(id, template, name) {
      this.$router.push({
        name: "AddRecipients",
        query: {
          template_id: id,
          templateType: template.id,
          title: name,
          type: +this.$route.query.type,
        },
      });
    },
    navigateToEditPass(id, template, name) {
      this.$router.push({
        name: "EditPass",
        query: {
          template_id: id,
          templateType: template.id,
          title: name,
        },
      });
    },
    onClick() {
      this.show = !this.show;
    },
    getDropId(index) {
      if (index == 1) {
        this.$router.push("");
      }
      if (index == 2) {
        this.droptemplate("shsh");
      }
    },
    droptemplate(cardId) {
      this.$store.commit("DELETE_MODAL_VISIBLE", true);
      this.cardIdDlt = +cardId;
      this.cardId = +cardId;
      // let payload = {
      //   id: this.clubId,
      //   templateId: id,
      // };
      // this.$store.dispatch("deletePassTemplates", payload);
    },
    cardNameFormat(name) {
      return name.length <= 50 ? name : name.substring(0, 50) + "..";
    },
    isClub() {
      return this.club.isAssociation ? "Association" : "Club";
    },
  },
};
</script>
<style scoped></style>
