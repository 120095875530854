<AppSnackBar :message="showMsg" :notificationType="'success'" v-if="isshowMessage"></AppSnackBar>
<app-loader v-if="isLoading"></app-loader>
<div class="dashboard-right-inner">
  <Breadcrumb :id="$route.params.id" />

  <div class="max-height-100--60 overflow-y-auto">

    <div class="dashboard-header">
      <div class="mb-4">
        <h2 class="font-28 font-34-sm-big font-w-300">Digital Pass Templates</h2>
        <p class="font-14 color-light2 m-0">
          Set-up your digital pass template information for {{isClub()}} members
        </p>
      </div>

      <div v-if="isItemsavailable || searchingLoading" class="d-flex flex-wrap justify-content-between align-items-end">
        <div class="d-flex flex-wrap align-items-center w-100-vsm">

          <div class="search-box-wrap width-220 w-100-vsm mb-3 me-4 mr-0-vsm">
            <span class="k-icon k-i-zoom"></span>
            <k-input :class="'mb-0'" placeholder="Search" :value="searchQuery" @input="onSearch"></k-input>
          </div>
          <span class="mb-3 w-100-vsm">
            <label class="pe-2 w-100-vsm">Filter by Type:</label>
            <dropdownlist :class="'w-220-vsm w-100-vsm'" :style="{ width: '190px' }" :data-items="templates"
              :text-field="'text'" :data-item-key="'id'" :value="passTypesValue" @change="handlePassTypesValue">
            </dropdownlist>
          </span>
        </div>

        <kbutton :disabled="!hasCreateAccess()" :class="'mb-3 w-100-vsm'" :theme-color="'primary'" @click="navigateToCreatePage">Create Digital Pass
        </kbutton>
      </div>
    </div>

    <!--dashboard-content-->
    <div class="dashboard-content pb-0 px-3">
      <div class="row row-22">

        <div v-if="fetchController=='available'" class="col-xxl-3 col-xxl-3-1670 col-xl-4 col-lg-6 col-md-6 col-sm-12"
          v-for="(card, index) in passItems">
          <div class="template-card-grid">
            <card>
              <cardBody>
                <div class="d-flex justify-content-between align-items-center mb-3">

                  <span v-if="card.templateType.name == `Event Ticket`" class="event-type">Event Ticket</span>
                  <span v-if="card.templateType.name == `Loyalty`" class="event-type green">Loyalty</span>
                  <span v-if="card.templateType.name == `Coupon`" class="event-type blue">Coupon</span>
                  <span v-if="card.templateType.name == `Membership`" class="event-type blue">Membership</span>

                  <kbutton :disabled="!hasDeleteAccess()" class="p-0" :icon="'delete'" :fill-mode="'flat'"
                    @click="droptemplate(card.id)">
                  </kbutton>
                </div>

                <cardTitle v-if="card.name" class="font-19 font-w-500"> {{cardNameFormat(card.name)}}
                </cardTitle>

                <cardTitle v-else class="font-19 font-w-500">No Title
                </cardTitle>

                <div class="divider"></div>
              </cardBody>

              <cardActions>
                <kbutton :disabled="!hasAudienceGetAccess()"  :fill-mode="'flat'" :theme-color="'primary'"
                  @click="navigateToManageRecipients(card.id, card.templateType, card.name)">
                  Manage Recipients
                </kbutton>
                <kbutton :disabled="!hasEditAccess()" :fill-mode="'flat'" :theme-color="'primary'"
                  @click="navigateToEditPass(card.id, card.templateType, card.name)">Edit Pass
                </kbutton>

              </cardActions>
            </card>
          </div>
        </div>

        <div v-if="fetchController =='notavaiable'"
          class="d-flex flex-column justify-content-center align-items-center mt-5 pt-5">
          <svg width="217" height="184" viewBox="0 0 217 184" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle opacity="0.05" cx="109" cy="92" r="92" fill="#253858" />
            <g filter="url(#filter0_bdd_3058_77311)">
              <rect x="31.9999" y="51" width="155" height="103" rx="1.93432" fill="#DEDEDE" fill-opacity="0.13"
                shape-rendering="crispEdges" />
            </g>
            <g filter="url(#filter1_bdd_3058_77311)">
              <rect x="27.9999" y="42" width="162" height="108" rx="1.88497" fill="#DEDEDE" fill-opacity="0.13"
                shape-rendering="crispEdges" />
            </g>
            <g filter="url(#filter2_dd_3058_77311)">
              <rect x="22.9999" y="31" width="171" height="115" rx="3.69388" fill="white" />
            </g>
            <rect x="33.9999" y="121" width="40" height="7" rx="2" fill="#D1E1F9" fill-opacity="0.74" />
            <rect x="87.9999" y="121" width="40" height="7" rx="2" fill="#A4C3F3" />
            <rect x="143" y="121" width="40" height="7" rx="2" fill="#D1E1F9" fill-opacity="0.74" />
            <line x1="33.9999" y1="107.234" x2="182.679" y2="107.234" stroke="black" stroke-opacity="0.04"
              stroke-width="0.923471" />
            <rect x="77.9999" y="75" width="60.0256" height="7.36167" rx="3.68083" fill="#A4C3F3" />
            <rect opacity="0.5" x="85.9999" y="88.8203" width="44.9329" height="4.76792" rx="2.38396" fill="#A4C3F3" />
            <rect x="93.9999" y="41" width="27.7041" height="27.7041" rx="13.8521" fill="#A4C3F3" />
            <defs>
              <filter id="filter0_bdd_3058_77311" x="23.9607" y="42.9609" width="171.078" height="119.078"
                filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feGaussianBlur in="BackgroundImage" stdDeviation="4.01957" />
                <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_3058_77311" />
                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                  result="hardAlpha" />
                <feOffset dy="1.81524" />
                <feGaussianBlur stdDeviation="1.81524" />
                <feComposite in2="hardAlpha" operator="out" />
                <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.03 0" />
                <feBlend mode="normal" in2="effect1_backgroundBlur_3058_77311" result="effect2_dropShadow_3058_77311" />
                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                  result="hardAlpha" />
                <feOffset dy="4.01957" />
                <feGaussianBlur stdDeviation="2.00978" />
                <feComposite in2="hardAlpha" operator="out" />
                <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.01 0" />
                <feBlend mode="normal" in2="effect2_dropShadow_3058_77311" result="effect3_dropShadow_3058_77311" />
                <feBlend mode="normal" in="SourceGraphic" in2="effect3_dropShadow_3058_77311" result="shape" />
              </filter>
              <filter id="filter1_bdd_3058_77311" x="20.205" y="34.2051" width="177.59" height="123.59"
                filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feGaussianBlur in="BackgroundImage" stdDeviation="3.89744" />
                <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_3058_77311" />
                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                  result="hardAlpha" />
                <feOffset dy="1.76009" />
                <feGaussianBlur stdDeviation="1.76009" />
                <feComposite in2="hardAlpha" operator="out" />
                <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.08 0" />
                <feBlend mode="normal" in2="effect1_backgroundBlur_3058_77311" result="effect2_dropShadow_3058_77311" />
                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                  result="hardAlpha" />
                <feOffset dy="3.89744" />
                <feGaussianBlur stdDeviation="1.94872" />
                <feComposite in2="hardAlpha" operator="out" />
                <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.01 0" />
                <feBlend mode="normal" in2="effect2_dropShadow_3058_77311" result="effect3_dropShadow_3058_77311" />
                <feBlend mode="normal" in="SourceGraphic" in2="effect3_dropShadow_3058_77311" result="shape" />
              </filter>
              <filter id="filter2_dd_3058_77311" x="0.836578" y="16.2245" width="215.327" height="159.327"
                filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                  result="hardAlpha" />
                <feOffset dy="7.38777" />
                <feGaussianBlur stdDeviation="11.0816" />
                <feComposite in2="hardAlpha" operator="out" />
                <feColorMatrix type="matrix" values="0 0 0 0 0.04 0 0 0 0 0.168627 0 0 0 0 0.36 0 0 0 0.08 0" />
                <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3058_77311" />
                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                  result="hardAlpha" />
                <feOffset dy="1.84694" />
                <feGaussianBlur stdDeviation="1.84694" />
                <feComposite in2="hardAlpha" operator="out" />
                <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0" />
                <feBlend mode="normal" in2="effect1_dropShadow_3058_77311" result="effect2_dropShadow_3058_77311" />
                <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_3058_77311" result="shape" />
              </filter>
            </defs>
          </svg>
          <h5 v-if="!searchQuery && !isLoading && fetchController == 'notavaiable' && !searchingLoading"
            class=" font-19 text-color mt-5">
            Digital Pass</h5>
          <p v-if="!searchQuery && !isLoading && fetchController == 'notavaiable' && !searchingLoading"
            class=" font-14 color-light2 mb-4">
            Generate Apple and Google digital pass
            for your
            {{isClub()}} members.</p>
          <p v-if="(searchQuery && fetchController == 'notavaiable') || isLoading || searchingLoading"
            class="font-14 color-light2 mb-4">No
            digital
            pass found. </p>
          <kbutton v-if="(!searchQuery && !isLoading && fetchController == 'notavaiable' && !searchingLoading)" :disabled="!hasCreateAccess()"
            :class="'mb-3'" :theme-color="'primary'" @click="navigateToCreatePage">Create
            Digital Pass</kbutton>
        </div>

      </div>
    </div>

  </div>

  <DigitalPassTemplateDeleteModal :cardId="cardIdDlt" :passTypesValue="passTypesValue" :clubId="clubId"
    v-if="deleteModalVisible" />
</div>